
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { AuthLayout, Form, PasswordInput, TextInput } from '@app/components';
import { nextAuthOptions } from '@app/lib/server';
import { Alert, Button, Divider } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { IconLogin, IconSend } from '@tabler/icons-react';
import { type GetServerSideProps, type NextPage } from 'next';
import Link from 'next/link';
import { type Session } from 'next-auth';
import { type SignInErrorTypes } from 'next-auth/core/pages/signin';
import { getServerSession } from 'next-auth/next';
import { signIn } from 'next-auth/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';
interface LoginFormData {
    email: string;
    password: string;
}
interface LoginPageQueryParams {
    callbackUrl?: string;
    error?: SignInErrorTypes;
}
const LoginPage: NextPage = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const errors = useMemo<Record<SignInErrorTypes, string>>(() => {
        const basicError = t('Try logging in with a different account');
        return {
            Signin: basicError,
            OAuthSignin: basicError,
            OAuthCallback: basicError,
            OAuthCreateAccount: basicError,
            EmailCreateAccount: basicError,
            Callback: basicError,
            OAuthAccountNotLinked: t('To confirm your identity, log in with the same account you used originally'),
            EmailSignin: t('The email could not be sent'),
            CredentialsSignin: t('Incorrect email or password'),
            SessionRequired: t('Please log in to access this page'),
            default: t('Unable to login')
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.locale, t]);
    const { callbackUrl = '/', error: errorCode }: LoginPageQueryParams = router.query;
    const [error, setError] = useState(() => errorCode && errors[errorCode]);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const form = useForm<LoginFormData>({
        initialValues: {
            email: '',
            password: ''
        },
        validate: zodResolver(z.object({
            email: z.string().email({
                message: t('Enter a valid email')
            }),
            password: z.string()
        })),
        validateInputOnChange: false
    });
    return (<AuthLayout loading={isLoggingIn} title={t('Login')}>
      <Button color='blue' component={Link} href={`/auth/request-link?callbackUrl=${callbackUrl}`} fullWidth leftIcon={<IconSend size={18}/>}>
        Login with Secure Link
      </Button>
      <Divider label={t('or')} labelPosition='center' size='xs' my='xs'/>
      <Form buttons={<Button color='blue' disabled={isLoggingIn} fullWidth leftIcon={<IconLogin size={18}/>} type='submit' variant='subtle'>
            {t('Login')}
          </Button>} onSubmit={form.onSubmit(useCallback(async (values) => {
            setIsLoggingIn(true);
            const { error: nextError, ok, url } = await signIn('credentials', {
                ...values,
                callbackUrl,
                redirect: false
            });
            if (!ok) {
                setError(nextError && (errors[nextError] ?? errors.default));
                setIsLoggingIn(false);
            }
            else {
                await router.push(url);
            }
        }, [callbackUrl, errors, router]))}>
        <TextInput autoComplete='email' disabled={isLoggingIn} error={form.errors.email} icon={null} mb='xs' onChange={useCallback((event) => {
            setError(null);
            form.setFieldValue('email', event.currentTarget.value);
        }, [form])} placeholder='user@email.com' required type='email' value={form.values.email}/>
        <PasswordInput autoComplete='current-password' disabled={isLoggingIn} error={form.errors.password} icon={null} mb='xl' onChange={useCallback((event) => {
            setError(null);
            form.setFieldValue('password', event.currentTarget.value);
        }, [form])} placeholder='password' required requirements={null} value={form.values.password}/>
        {error && (<Alert color='red' mb='lg' radius='sm' variant='filled'>
            {error}
          </Alert>)}
      </Form>
    </AuthLayout>);
};
const getServerSideProps: GetServerSideProps<{
    session: Session;
}, {
    callbackUrl?: string;
}> = async ({ req, res, params }) => {
    const session = await getServerSession(req, res, nextAuthOptions);
    if (session?.user?.id) {
        return {
            redirect: {
                destination: (params?.callbackUrl as string) || req.headers.referer || '/',
                permanent: false
            }
        };
    }
    return {
        props: {
            session
        }
    };
};
// noinspection JSUnusedGlobalSymbols
export default LoginPage;

    async function __Next_Translate__getServerSideProps__18a3e4c7c58__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/auth/login',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__18a3e4c7c58__ as getServerSideProps }
  